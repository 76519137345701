/* typography */

h1 {
  font-size: 40px;
  font-weight: 600;
}
h5 {
  font-size: 22px;
}
h6 {margin-bottom: 0;}
p,
p strong {
  font-size: 16px;
}
/* ------------ */
/* app.js */
#header {
  background-color: #f6f8fa !important;
}

header > div {
  border-bottom: 1px solid #ebebeb;
}

.header {
  display: flex; justify-content: space-between; align-items: center;
}

.header-wrapper {
  display: flex; align-items: center;
}

.App-logo {
  height: 80px;
  pointer-events: none;
}

.screen-size {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-selected {
  background-color: rgba(25, 118, 210, 0.04) !important;
}

.workspace-btn {
  width: 40px !important;
  min-height: unset !important;
  height: 23px;
  font-size: 11px !important;
  text-transform: capitalize !important;
  background-color: #454545 !important;
}

.scrollbar-list {
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  height: 50px;
  width: auto;
  max-width: 650px;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.project-list li a {
  padding: 2px 15px 5px;
  border-radius: 30px;
  color: #000;
  background: #f6f8fa;
}

.project-list li a:hover {
  background: #f6f8fa;
  border-radius: 30px;
}

.project-list li a.selected {
  background-color: #1976d2;
  color: #fff;
}
/* ---------- */
/* authentication page */
.postman-login {
  padding: 100px 0;
}
.postman-signup {
  padding: 80px 0;
}
.custom-icon {
  color: #fff;
}
.email-field,
.password-field {
  width: 100%;
  max-width: 500px !important;
}
.email-field .MuiInputBase-root {
  border-radius: 30px;
}
.password-field .MuiInputBase-root {
  border-radius: 30px;
}
.postman-login-col-right {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(44, 44, 44, 0.8)),
    url(./assets/images/login-background.jpg);
  background-size: cover;
  height: 100%;
}
/* --------- */
/* home */
.MuiAccordionSummary-root {
  max-height: 48px;
  min-height: unset !important;
}
.main-accordion-summary .MuiAccordionSummary-content {
  margin: 8px 0;
}

.textoutput {
  background: #282c34 !important;
}

.textoutput textarea {
  color: #fff;
}

.GET {
  background-color: #7ab7f5bd !important;
  border: 3px solid #007fff !important;
}
.POST {
  background-color: #77f5abbd !important;
  border: 3px solid #019630 !important;
}
.PUT {
  background-color: #f7b564bd !important;
  border: 3px solid #ff8d00 !important;
}
.DELETE {
  background-color: #fa7575bd !important;
  border: 3px solid #ff0000 !important;
}

.item-style::-webkit-scrollbar {
  width: 5px;
}

.item-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.item-style::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px;
}

.summary .MuiAccordionSummary-content {
  margin: 8px 0;
}

.tab-class {
  display: flex !important;
  flex-direction: row-reverse !important;
}

.css-jpln7h-MuiTabs-scroller {
  height: 50px;
}

.mui-icon {
  margin-bottom: 0 !important;
  color: #1976d2 !important;
  margin-left: 8px;
}

.textfield-without-outline:focus {
  outline: none !important;
}

/* Nested Accordion */
.table-select fieldset {
  border: none !important;
}

.formdata-table tr td,
.header-table tr td,
.params-table tr td {
  vertical-align: middle;
}

/* Syntax Highlighter */
/* .syntax-highlighter pre {
  max-height: 400px;
} */
.textoutput {
  max-height: 200px !important;
}
.border-bottom-heading{
  color: #000;
  display: inline-block;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.env-list li:not(:last-child) {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #bebebe;
}

.env-left-list {
  height: 100px;
  overflow-y: scroll;
  overflow-x: clip;
}

.env-left-list::-webkit-scrollbar-track {
    background-color: #ccc;
}

.env-left-list::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}
.env-left-list::-webkit-scrollbar {
    width: 4px;
}

.env-right-list .para-title {
  margin-right: 5px;
  text-transform: uppercase;
}

.env-right-list p {
  font-size: 12px;
  font-weight: 500;
  /* overflow-wrap: anywhere; */
  margin-bottom: 0;
}


@media screen and (max-width: 768px) {
  .postman-login-col-right {
    padding: 30px 0;
  }
}
@media screen and (max-width: 500px) {
  .header { flex-direction: column-reverse; }
  .header-navbar { flex-direction: column-reverse; }
  .header-navbar-list { width: 100%; max-width: 320px; }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
